import React, { useState } from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { appName } from '../../Global/Global'
import CreateUrlModal from './CreateUrlModal'


const CreateVideo = () => {

    const rebrand = useSelector(state => state.rebrand.data)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [modalData, setModalData] = useState({
        name: "Blog url to video",
        isBlog: true
    })

    const handleShow = (type) => {
        if (type === "blog") {
            setModalData({
                ...modalData,
                name: "Blog url to video",
                isBlog: true
            })
        } else {
            setModalData({
                ...modalData,
                name: "E-Commerce url to video",
                isBlog: false
            })
        }
        setShow(true)
    };


    return (
        <div className="created-by">
            <h2 className="text-center">Create Video - Pick An Option:</h2>
            <div className="row">
                <div className="col-lg-4">
                    <Link to="/create-content">
                        <div className="create-details">
                            <span className="card__line card__line_left"></span>
                            <span className="card__line card__line_right"></span>
                            <span className="card__line card__line_top"></span>
                            <span className="card__line card__line_bottom"></span>
                            <div className="create-details-top">
                                <h3>Copy & Paste Your Content</h3>
                                <h4>1</h4>
                            </div>
                            <div className="create-details-bottom">
                                <p>With {rebrand ? rebrand.name : appName}, effortlessly transform your content into engaging videos. Just copy and paste your material, and let the software work its magic, creating appealing visual content that resonates with viewers.</p>
                                <span><BsArrowRight /></span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-4">
                    <Link onClick={() => handleShow("blog")}>
                        <div className="create-details">
                            <span className="card__line card__line_left"></span>
                            <span className="card__line card__line_right"></span>
                            <span className="card__line card__line_top"></span>
                            <span className="card__line card__line_bottom"></span>
                            <div className="create-details-top">
                                <h3>Blog Post or Articles</h3>
                                <h4>2</h4>
                            </div>
                            <div className="create-details-bottom">
                                <p>Turn your written words into powerful visuals. {rebrand ? rebrand.name : appName} takes your blogs and articles, animating them into professional videos, enhancing their appeal and effectiveness in capturing the audience’s attention.</p>
                                <span><BsArrowRight /></span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-4">
                    <Link onClick={() => handleShow("ecom")}>
                        <div className="create-details">
                            <span className="card__line card__line_left"></span>
                            <span className="card__line card__line_right"></span>
                            <span className="card__line card__line_top"></span>
                            <span className="card__line card__line_bottom"></span>
                            <div className="create-details-top">
                                <h3>E-Commerce Detail Page</h3>
                                <h4>3</h4>
                            </div>
                            <div className="create-details-bottom">
                                <p>Elevate your e-commerce pages by converting them into dynamic videos. {rebrand ? rebrand.name : appName} creates visually captivating videos from your product details, creating a richer, more engaging shopping experience for customers.</p>
                                <span><BsArrowRight /></span>
                            </div>
                        </div>
                    </Link>
                </div>
            </div >

            <CreateUrlModal
                show={show}
                handleClose={handleClose}
                modalData={modalData}
            />
        </div >
    )
}

export default CreateVideo