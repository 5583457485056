import React, { useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import AccountNav from "./AccountNav";
import { useDispatch, useSelector } from "react-redux";
import { gdprAction, onChangeEmail } from "../../../Redux/Actions/AuthActions";
import { appName } from "../../../Global/Global";
import SweetAlert from "../../CommonComponents/SweetAlert";
import TitleBar from "../../CommonComponents/TitleBar";
import { FaSpinner } from "react-icons/fa";


const Privacy = () => {

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const rebrand = useSelector(state => state.rebrand.data)

  const [email, setEmail] = useState("")
  const [loader, setLoader] = useState(false)

  const [sweet, setSweet] = useState({
    enable: false,
    id: false,
    confirmButtonName: "",
    text: "",
    ep: "",
    loader: false
  })

  const modalData = [
    {
      message: `${rebrand ? rebrand.name : appName} stores your private information. If you wish to download these data, you can do that by clicking Download My Data button.`
    },
    {
      message: 'Are you sure you want to stop processing your data? Doing so will disable your account and in order to reactivate your'
    },
    {
      message: 'Are you sure you want to delete your data? This action is permanent and cannot be reversed.'
    }

  ]
  const performAction = () => {
    setSweet({
      ...sweet,
      loader: true
    })
    dispatch(gdprAction('', sweet.ep, sweet, setSweet))
  }

  const handleClick = (index) => {
    setSweet({
      ...sweet,
      enable: true,
      id: index,
      text: modalData[index].message,
      confirmButtonName: index === 0 ? "Download" : index === 1 ? "Stop Processing" : "Delete Data",
      ep: index === 0 ? "download-user-data" : index === 1 ? "disable-account" : "delete-account"
    })
  }

  const onCancelDelete = () => {
    setSweet({
      ...sweet,
      enable: false,
      id: false,
      ep: "",
      loader: false
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      email: email
    }
    setLoader(true)
    dispatch(onChangeEmail(data, setLoader))
  }

  return (
    <>
      <Header />
      <TitleBar title="Privacy" />

      <div className="site-wrap">
        <div className="site-container">
          <div className="account-wrap">
            <div className="account-nav">
              <AccountNav />
            </div>

            <div className="account-cont">
              <h2 className="account-title">Privacy Settings</h2>

              <form className="account-block" onSubmit={handleSubmit}>
                <h5>Personal Info</h5>
                <div className="input-wrap">
                  <label htmlFor="">Current Email Address</label>
                  <div className="input-outer">
                    <input
                      className="common-inp alt"
                      type="text"
                      value={auth.user.email}
                      readOnly
                    />
                  </div>
                </div>
                <div className="input-wrap">
                  <label htmlFor="">New Email Address</label>
                  <div className="input-outer">
                    <input
                      className="common-inp alt"
                      type="email"
                      placeholder={auth.user.email}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className='alert alert-info text-center mt-3'>
                  <div className='info-msg'>Updating your email will log you out for re-login.</div>
                </div>
                <div className="input-wrap text-end">
                  <button className="theme-btn"><span>{loader ? <>Updating  <FaSpinner className="spin" /></> : "Update"}</span></button>
                </div>
              </form>

              <div className="account-block">
                <div className="privacy-block">
                  <h5>Manage your data</h5>
                  <p> {rebrand ? rebrand.name : appName} stores your private information like your name, email. It also stores your uploaded graphics such as images, videos and your music files along with TTS ( Text to speech). If you wish to download these data, you can do that "by clicking" download your data.</p>
                  <button
                    className="theme-btn mt-4"
                    onClick={() => handleClick(0)}
                  ><span>Download Your Data</span></button>
                </div>
                <div className="privacy-block">
                  <h5>Manage your data</h5>
                  <p>If you wish to stop your private data from getting processed on  {rebrand ? rebrand.name : appName} you can click the button to do so. Please note that doing this will disable your account and you will not be able to use  {rebrand ? rebrand.name : appName} further. In order to reactivate your account, you would have to contact our support team at support@vineasx.com. Please Proceed With Caution.</p>
                  <button
                    className="theme-btn mt-4"
                    onClick={() => handleClick(1)
                    }
                  ><span>Stop Processing My Data</span></button>
                </div>
                <div className="privacy-block">
                  <h5>Manage your data</h5>
                  <p>If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use  {rebrand ? rebrand.name : appName} further. You cannot undo this step from support also. So if you wish to re-use  {rebrand ? rebrand.name : appName} again, then you will have to start all over again from scratch. Please Proceed With Caution.</p>
                  <button
                    className="theme-btn mt-4"
                    onClick={() => handleClick(2)
                    }
                  ><span>Delete Your Data</span></button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <SweetAlert
        show={sweet.enable}
        message={sweet.text}
        confirmButtonColor={""}
        cancelButtonColor={""}
        confirmButtonName={sweet.confirmButtonName}
        cancelButtonName={"Cancel"}
        handleClose={onCancelDelete}
        performDelete={performAction}
        alertLoader={sweet.loader}
      />
      <Footer />
    </>
  )
}

export default Privacy;