import React from 'react'
import { useState } from 'react'
import { BiTrash, BiChevronDownSquare, BiSearch } from "react-icons/bi"
import { MdModeEditOutline } from "react-icons/md"
import { useDispatch } from 'react-redux'
import { onDeleteAcc } from '../../../Redux/Actions/AccountManageAction'
import { useEffect } from 'react'
import SweetAlert from '../../CommonComponents/SweetAlert'
import EditMemberModal from './EditMemberModal'

const MemberRow = ({ curElem, fetchMembers, type }) => {

    const dispatch = useDispatch()
    const [user, setUser] = useState({
        id: false,
        name: "",
        email: "",
        type: "",
        created: ""
    })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader:false
    })
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onDelete = (tId) => {
        setSweet({
            ...sweet,
            enable: true,
            id: tId
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader : false
        })
    }

    const performDelete = () => {
        let data = {
            client_id: sweet.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader : true
        })
        dispatch(onDeleteAcc(data, fetchMembers, setSweet))
    }

    useEffect(() => {
        setUser({
            ...user,
            id: curElem.id,
            name: curElem.name,
            email: curElem.email,
            type: curElem.type,
            created: curElem.created
        })
    }, [curElem])

    return (
        <tr>
            <td>{curElem.name}</td>
            <td>{curElem.email}</td>
            <td>{curElem.created}</td>
            <td className="text-end">
                <span className="table-icon me-1" style={{ cursor: "pointer" }} onClick={handleShow} ><MdModeEditOutline /></span>
                <span className="table-icon" style={{ cursor: "pointer" }} onClick={() => onDelete(user.id)}><BiTrash /></span>
            </td>

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure, you want to delete this member?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                />

            <EditMemberModal
                show={show}
                handleClose={handleClose}
                type={type}
                user={user}
                fetchMembers={fetchMembers}
            />
        </tr>
    )
}

export default MemberRow