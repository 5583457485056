import React, { useMemo } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { AbsoluteFill, Video, Img, interpolate, useCurrentFrame, spring, useVideoConfig, OffthreadVideo } from 'remotion';

const SlideBackground = ({ playerRef, dimension, animation, src, duration, type }) => {

    const [loader, setLoader] = useState(false)

    const frame = useCurrentFrame();
    const { fps } = useVideoConfig();

    let mystyle = {
        scale: "",
        translate: "",
    }

    if (animation.name === "zoominheader") {
        mystyle.scale = String(
            interpolate(frame, [0, duration], [1, 1.5], {
                extrapolateLeft: "clamp",
                extrapolateRight: "clamp",
            })
        )
    } else if (animation.name === "zoomoutheader") {
        mystyle.scale = String(
            interpolate(frame, [0, duration], [1.5, 1], {
                extrapolateLeft: "clamp",
                extrapolateRight: "clamp",
            })
        )
    } else if (animation.name === "zoomright") {
        const left = spring({
            fps,
            frame,
            config: {
                damping: 200,
            },
            durationInFrames: duration,
        });

        const x = interpolate(left, [0, duration / 30], [0, -400]);
        mystyle.translate = `${x}px ${0}px`
        mystyle.scale = String(
            interpolate(frame, [0, duration], [1.5, 1.5], {
                extrapolateLeft: "clamp",
                extrapolateRight: "clamp",
            })
        )

    } else {
        const right = spring({
            fps,
            frame,
            config: {
                damping: 200,
            },
            durationInFrames: duration,
        });

        const x = interpolate(right, [0, duration / 30], [0, 400]);
        mystyle.translate = `${x}px ${0}px`
        mystyle.scale = String(
            interpolate(frame, [0, duration], [1.5, 1.5], {
                extrapolateLeft: "clamp",
                extrapolateRight: "clamp",
            })
        )
    }

    useEffect(() => {
        if (type === "video") {
            if (playerRef.current) {
                playerRef.current.pause()
                setLoader(true)
            }
        }
    }, [type]);

    return (
        <AbsoluteFill >
            {
                loader ?
                    <div
                        className="loader-center-temp1"
                        style={
                            {
                                background: 'linear-gradient( rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7)50%)',
                                width: "100%",
                                height: "100%",
                                position: 'absolute',
                                zIndex: "2"
                            }}
                    >
                        <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "50px" }} />
                    </div> : ''
            }
            {type === "video" ?
                <Video
                    key={src}
                    style={{ ...mystyle, objectFit: dimension === "9x16" ? "cover" : "contain", height: "100%", width: "100%" }}
                    src={src}
                    volume={1}
                    playbackRate={2} //speed
                    quality="draft"
                    muted={true}
                    loop={true}
                    onError={(event) => {
                        // Handle image loading error here
                        console.log("error")
                    }}

                    onLoadedData={
                        () => {
                            if (playerRef.current) {
                                setLoader(false)
                                playerRef.current.play()
                            }
                        }
                    }
                />
                :
                type === "image" ?
                    <Img
                        key={src}
                        style={{ ...mystyle, objectFit: dimension === "9x16" ? "cover" : "contain", height: "100%", width: "auto" }}
                        src={src}
                        onError={(event) => {
                            // Handle image loading error here
                            console.log("error")
                        }}
                    />
                    :
                    <AbsoluteFill style={{ background: src }} />
            }
        </AbsoluteFill >
    )
}

export default SlideBackground
