import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { GiCheckMark } from 'react-icons/gi'
import { useEffect } from 'react'
import HoverVideoPlayer from "react-hover-video-player";
import { FaSpinner } from 'react-icons/fa'

const WebMedia = ({ handleBackground, checkMedia, setCheckMedia }) => {
    const campaign = useSelector(state => state.video.campaign)
    const [data, setData] = useState([])

    const handleSelectImage = (data) => {
        if (data.type === "image") {
            handleBackground(data.url, "ui")
        } else {
            handleBackground(data, "video")
        }
        setCheckMedia(data.url)

    }

    useEffect(() => {
        if (campaign.campaign) {
            setData(JSON.parse(campaign.campaign.media))
        }
    }, [campaign.campaign])

    return (
        <div className='photo-scroll' >
            {
                data.length > 0 ?
                    <>
                        <div className="row sm mb-3">
                            {
                                data.map((curElem, index) => {
                                    return (
                                        <div className="col-6" key={index}>
                                            <div className="photo-single" style={{ cursor: "pointer", border: `${checkMedia === curElem.url ? 2 : 1}px solid #00D0B0`, borderRadius: "12px", transition: "none" }} onClick={() => handleSelectImage(curElem, index)} >
                                                {curElem.type === "image" ?
                                                    <img alt="" src={curElem.url} /> :
                                                    <HoverVideoPlayer
                                                        style={{ height: '100%' }}
                                                        videoSrc={curElem.url}
                                                        pausedOverlay={
                                                            <img
                                                                src={curElem.thumb}
                                                                alt={``}
                                                                style={{ padding: 2 }}
                                                            />
                                                        }
                                                        loadingOverlay={
                                                            <div style={{ color: "#00c4a6", display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
                                                                <FaSpinner className="spin" size={20} />
                                                            </div>
                                                        }
                                                    />
                                                }
                                                {checkMedia === curElem.url ?
                                                    <div className='select-tile-style' style={{ border: "none" }}>
                                                        <GiCheckMark />
                                                    </div> : ""}
                                            </div>
                                        </div>

                                    )
                                })}

                        </div>
                    </>
                    :

                    <div className='text-center mt-3'>
                        <p>No web media available!</p>
                    </div>
            }


        </div >
    )
}

export default WebMedia