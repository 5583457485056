import React from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { useEffect } from 'react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import HoverVideoPlayer from "react-hover-video-player";
import { GiCheckMark } from 'react-icons/gi';
import { onDeleteUserMedia } from '../../Redux/Actions/VideoActions';
import { fetchData, onUploadMedia } from '../../Redux/Actions/CommonActions';
import SweetAlert from './SweetAlert';
import { FiUpload } from 'react-icons/fi';
import { FaSpinner } from 'react-icons/fa';

const Upload = ({ handleBackground, checkMedia, setCheckMedia, from }) => {
    const dispatch = useDispatch()
    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        fetch: false,
        upload: false
    })
    const [images, setImages] = useState([])
    const [videos, setVideos] = useState([])


    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        type: "",
        loader: false
    })

    const handleDelete = (e, id, type) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            id: id,
            enable: true,
            type: type,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            type: "",
            loader: false
        })
    }

    const performDelete = () => {
        const formData = new FormData()
        formData.append("id", sweet.id)
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        if (sweet.type === "images") {
            dispatch(onDeleteUserMedia(formData, fetchImgMedia, setSweet))
        }
        else {
            dispatch(onDeleteUserMedia(formData, fetchVidMedia, setSweet))
        }
    }

    const onInputChange = (e) => {
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, fetchImgMedia, loader, setLoader, setPercent))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: "#00D0B0"
                    })
                }
            } else if (['video/mp4'].includes(e.target.files[0].type) && (from !== "imgEditor")) {
                if (e.target.files[0].size < 20000000) {
                    formData.append('upload_type', "video")
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, fetchVidMedia, loader, setLoader, setPercent))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for video is 20MB!',
                        confirmButtonColor: "#00D0B0"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: "#00D0B0"
                })
            }
        }
    }

    const handleSelectImage = (data) => {
        handleBackground(data.url, "ui")
        setCheckMedia(data.url)
    }
    const handleSelectVideo = (data) => {
        handleBackground(data, "video")
        setCheckMedia(data.url)
    }


    const fetchImgMedia = () => {
        const data = {
            type: "images"
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-user-uploaded-file", data, setImages, loader, setLoader))
    }

    const fetchVidMedia = () => {
        const data = {
            type: "video"
        }
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-user-uploaded-file", data, setVideos, loader, setLoader))
    }

    useEffect(() => {
        fetchImgMedia()
        if (from !== "imgEditor") {
            fetchVidMedia()
        }
    }, [])

    return (
        <>
            <div className='upload-box'>
                <div className="upload">
                    {loader.upload ?
                        <div className='percent-loader-box'>
                            <span className='loader-percent'>{percent}%</span>
                        </div>
                        :
                        <>
                            <FiUpload />
                            <p>Upload {from === "imgEditor" ? "JPEG and PNG" : "Media"}</p>
                            <input
                                type="file"
                                onChange={onInputChange}
                            />
                        </>}
                </div>

            </div>
            <div className='photo-scroll' style={{ maxHeight: '378px', overflowY: "auto", padding: 10, }}>
                {
                    images.length > 0 ?
                        <>
                            <label>Images</label>
                            <div className="row sm mb-3">
                                {
                                    images.map((curElem, index) => {
                                        return (
                                            <div className="col-6" key={index}>
                                                <div className="photo-single" style={{ cursor: "pointer", border: `${checkMedia === curElem.url ? 2 : 1}px solid #00D0B0`, borderRadius: "12px", transition: "none" }} onClick={() => handleSelectImage(curElem, index)} >
                                                    <img alt="" src={curElem.url} />
                                                    <span className='list-delete' style={{ zIndex: 2 }} onClick={(e) => handleDelete(e, curElem.id, "images")}><AiOutlineDelete /></span>
                                                    {checkMedia === curElem.url ?
                                                        <div className='select-tile-style' style={{ border: "none" }}>
                                                            <GiCheckMark />
                                                        </div> : ""}
                                                </div>
                                            </div>

                                        )
                                    })}

                            </div>
                        </>
                        : ""}

                {
                    videos.length > 0 && from !== "imgEditor" ?
                        <>
                            <label>Videos</label>
                            <div className="row sm">
                                {
                                    videos.map((curElem, index) => {
                                        return (
                                            <div key={index} className='col-6'>
                                                <div className='photo-single' style={{ cursor: "pointer", }}>
                                                    <span onClick={() => handleSelectVideo(curElem, index)} style={{ height: "100%" }}>
                                                        <HoverVideoPlayer
                                                            style={{ height: "100%" }}
                                                            videoSrc={curElem.url}
                                                            pausedOverlay={
                                                                <img
                                                                    src={curElem.poster}
                                                                    alt={curElem.name}
                                                                    style={{ padding: 2 }}
                                                                />
                                                            }
                                                            loadingOverlay={
                                                                <div style={{ color: "#00c4a6", display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
                                                                    <FaSpinner className="spin" size={20} />
                                                                </div>
                                                            }
                                                        />
                                                        {checkMedia === curElem.url ?
                                                            <div className='select-tile-style' style={{ zIndex: 1 }}>
                                                                <GiCheckMark />
                                                            </div> : ""}
                                                    </span>
                                                    <span className='list-delete' style={{ zIndex: 2 }} onClick={(e) => handleDelete(e, curElem.id, "video")}><AiOutlineDelete /></span>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                        :
                        loader.fetch ?
                            <div className='text-center mt-2'>
                                {from !== "imgEditor" ?
                                    <FaSpinner className="spin" size={20} style={{ fontSize: 25, color: "#00c4a6" }} /> : ""}
                            </div> : ""
                }
                <SweetAlert
                    show={sweet.enable}
                    message={"Are you sure you want to delete this media?"}
                    confirmButtonColor={""}
                    cancelButtonColor={""}
                    confirmButtonName={sweet.confirmButtonName}
                    alertLoader={sweet.loader}
                    cancelButtonName={"Cancel"}
                    handleClose={onCancelDelete}
                    performDelete={performDelete}
                />
            </div >
        </>
    )
}

export default Upload