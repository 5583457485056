import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BsPlayFill, BsArrowRight, BsBoxArrowInDown, BsChevronDown } from "react-icons/bs"

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";
import Wabinar from "./Wabinar";
import TitleBar from "../CommonComponents/TitleBar";
import CreateVideo from "./CreateVideo";
import VideoProjects from "../CommonComponents/VideoProjects";
import PAUSE from "../../assets/images/PAUSE BUTTON.png"
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import BannerImg from "../../assets/images/appbanner.jpg";
const Dashboard = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showClose, setShowClose] = useState(false)

    const [showPopup, setShowPopup] = useState(false);
    const colorObj = {
        color: "#ffffff",
        position: "absolute",
        top: '-32px',
        right: "0px",
        fontSize: 11,
        background: "#515151",
        width: 150,
        textAlign: "center",
        padding: "8px 7px",
        borderRadius: 2,
    }
    const handelPopupClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    const handelClose2 = () => {
        setShowPopup(false);
    }

    const handleMsgClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }
    const handlePlayPause = () => {
        setShow(true)
    }

    useEffect(() => {
        const popupCookie = document.cookie
        if (popupCookie.includes(`popupClicked${auth.user.id}=true`)) {
            setShowPopup(false)
        } else {
            setShowPopup(true)
        }
    }, []);

    return (
        <>
            <Header />
            <TitleBar title="Dashborad" />
            <div className="site-wrap for-dashboard pt-0">
                <div className='hero-banner '>
                    <div className='hero-banner-video'>
                        <iframe src="https://player.vimeo.com/video/881619324?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="app intro video"></iframe>

                    </div>
                    <div className='container'>

                        <div className="welcome" style={{ position: "relative", zIndex: "2" }}>
                            <div className="welcome-top">
                                <h1>WELCOME TO {rebrand ? rebrand.name : <><span>CLIPS</span><span className="green">REEL AI</span></>}</h1>
                                <p>Create beautifully animated videos in seconds. See how {rebrand ? rebrand.name : appName}  uses its powerful AI to convert any  URL to a profitable animated video in any niche.</p>
                            </div>


                        </div>
                    </div>

                    <div className="welcome-video">
                        <div className="welcome-video-in">


                            <div className="play-btn" onClick={handlePlayPause}>

                                <img
                                    id="play-button"
                                    alt=""
                                    src={require("../../assets/images/play.png")}
                                    onClick={handlePlayPause}
                                />
                            </div>

                            <Modal className="theme-modal ai_img_wrap video-modal ds" show={showPopup} onHide={handelClose2} centered>
                                <Modal.Body>
                                    <div onClick={() => setShowClose(true)} className="vidClose" >
                                        <div style={{ position: "relative" }}>
                                            <div className='modalClose' onClick={handleClose}><AiOutlineClose style={{
                                                fontSize: "30px", marginBottom: "3px", top: '-72px',
                                                right: '-27px', position: "absolute"
                                            }} /></div>
                                            {showClose ?
                                                <p style={colorObj} onClick={handleMsgClick}>Don't show me again</p> : ""}

                                        </div>

                                    </div>
                                    <div className="modalVid">
                                        {/* <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick}>
                                            <img src={BannerImg} alt="bannerImg" title="AiStaff-Fb-Group" />
                                        </a> */}
                                        <div className="para_ai h-100">
                                            <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick} style={{ display: "block" }} className="w-100 d-flex h-100 justify-content-center align-items-center flex-column">
                                                <p style={{
                                                    fontSize: "55px",
                                                    fontWeight: "bold",
                                                    textAlign: "center",
                                                    lineHeight: "1.3",
                                                    color: "#fff"
                                                }}>Click Here To Join  Members <br /> Only Facebook Group </p>
                                                <button className="btn btn-danger mt-3 ps-4 pe-4 pt-2 pb-2" style={{ display: " block", fontSize: "45px" }}> Click Here</button>
                                            </a>
                                        </div>
                                    </div>

                                </Modal.Body>
                            </Modal>

                            <Modal className='theme-modal video-modal ds' show={show} onHide={handleClose} centered>
                                <Modal.Body className="p-0">
                                    <div className='modalClose text-end' onClick={handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>
                                    <div className="">
                                        <iframe src="https://player.vimeo.com/video/881969178?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" width="738" height="430" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="ClipsReelAI Walkthrough"></iframe>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
                {+auth.user.isClientAccount === 1 ? "" :
                    <CreateVideo />}

                {/* <div className="video-project">
                    <h2 className="text-center">Projects</h2>
                    <VideoProjects
                        isDash={true}
                    />
                </div> */}

                <div className="image-block">

                    <div className="image-block-single">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5>GET YOUR NEXT VIDEO CONTENT IN 60 SECONDS</h5>
                                    <h6>Create videos simply by pasting any URL or keyword</h6>
                                    <p>Your brand-new video is just a few clicks away. No more need to spend hours upon hours manually writing, editing, and rendering just to get your next upload out there. Now, all you need is a URL or a keyword and {rebrand ? rebrand.name : appName} powerful A.I. converts it into a video for you.</p>
                                    {/* <Link>Learn More <BsArrowRight /></Link> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-1.png')} /></div>
                            </div>
                        </div>
                    </div>

                    <div className="image-block-single">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5>POLISH YOUR VIDEOS EFFORTLESSLY</h5>
                                    <h6>Fully-customize and optimize all your videos</h6>
                                    <p>Forget about painstakingly editing in captions, graphics, logo, call to action, watermark, and more. {rebrand ? rebrand.name : appName} has a marketer-optimized editor that readily gives you all the tools that make all your videos unique and brandable.</p>
                                    {/* <Link>Learn More <BsArrowRight /></Link> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-2.png')} /></div>
                            </div>
                        </div>
                    </div>

                    <div className="image-block-single">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5>BOOST YOUR VIEWERSHIP AND WATCH TIME</h5>
                                    <h6>Get high-quality voiceover and captions easily</h6>
                                    <p>Captions and voiceovers are proven to drive your viewer count and watch time. And {rebrand ? rebrand.name : appName} makes it easy for you to get those. Simply paste your text to get a high-quality voiceover narration or upload your audio to get a ready-to-use caption and subtitle.</p>
                                    {/* <Link>Learn More <BsArrowRight /></Link> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-3.png')} /></div>
                            </div>
                        </div>
                    </div>

                    <div className="image-block-single">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="image-block-left">
                                    <h5>CREATE VIDEOS THAT STAND OUT</h5>
                                    <h6>Unlimited access to a rich media library</h6>
                                    <p>No more need to pay for third-party stock photo and video providers. Create captivating videos with unlimited access to {rebrand ? rebrand.name : appName}’s media library filled with copyright-free media elements for all your videos.</p>
                                    {/* <Link>Learn More <BsArrowRight /></Link> */}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="image-block-right"><img alt="" src={require('../../assets/images/img-4.png')} /></div>
                            </div>
                        </div>
                    </div>
                </div>

                <Wabinar />
            </div >

            <Footer />
        </>
    )
}

export default Dashboard;