import React from 'react'
import Footer from '../../Footer/Footer'
import Header from '../../Header/Header'
import { FiUpload } from 'react-icons/fi'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { onFetchCampaign, onUpdateCampaign } from '../../../Redux/Actions/VideoActions'
import queryString from "query-string"
import { FaSpinner } from 'react-icons/fa'
import { setAlert } from '../../../Redux/Actions/AlertActions'
import { onUploadMedia } from '../../../Redux/Actions/CommonActions'
import Swal from 'sweetalert2'
import { useImmer } from 'use-immer'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import thumb from '../../../assets/images/thumb.svg';
import unthumb from '../../../assets/images/Unthumb.svg';
import { AiOutlineCheck } from 'react-icons/ai'
import face from '../../../assets/images/do.png';
import face2 from '../../../assets/images/donts.png';


const FaceNarration = () => {
  let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const cId = queryString.parse(location.search).cid

  const [state, setState] = useImmer({
    id: false,
    name: "",
    preScript: "",
    dimension: "9x16",
    language: "",
    languageCode: "",
    style: "",
    country: "",
    type: "copy",
    media: [],
    faceNarration: {
      enable: false,
      media: {
        type: "",
        url: ""
      },
      coverage: "",
      headMovement: false
    }
  })
  const [loader, setLoader] = useState({
    upload: false
  })
  const [nextLoader, setNextLoader] = useState(false)
  const [percent, setPercent] = useState(0)
  const [freqMessage, setFreqMessage] = useState({
    color: "",
    message: ""
  })

  const handleChange = (e) => {
    const { name, value, checked } = e.target
    if (name === "coverage") {
      setState((draft) => {
        draft.faceNarration.coverage = value
      })

    } else if (name === "enable") {
      setState((draft) => {
        draft.faceNarration.enable = checked
      })
    } else if (name === "headMovement") {
      setState((draft) => {
        draft.faceNarration.headMovement = checked
      })
    }
  }

  useEffect(() => {
    if (state) {
      if (+state.faceNarration.coverage === 10) {
        setFreqMessage({
          ...freqMessage,
          message: "Video Rendering will take normal time.",
          color: "success"
        })
      } else if (+state.faceNarration.coverage === 25) {
        setFreqMessage({
          ...freqMessage,
          message: "Rendering will take slightly more time then normal.",
          color: "warning"
        })
      } else if (+state.faceNarration.coverage === 50) {
        setFreqMessage({
          ...freqMessage,
          message: "Rendering will take longer time then normal.",
          color: "danger"
        })
      }
    }
  }, [state])

  const onSuccessUpload = (path, type) => {
    setState((draft) => {
      draft.faceNarration.media.url = path
      draft.faceNarration.media.type = type
    })
  }

  const onInputFile = (e) => {
    if (e.target.files.length > 0) {
      let fileSize = e.target.files[0].size
      if (allowedExt.includes(e.target.files[0].type)) {
        if (fileSize < 5000000) {
          const formData = new FormData()
          formData.append('upload_type', "images")
          formData.append('file', e.target.files[0])
          dispatch(onUploadMedia(formData, onSuccessUpload, loader, setLoader, setPercent, "image"))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Max allowed size for images is 5MB!',
            confirmButtonColor: "#00d0b0"
          })
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You have Selected Invalid File Type!',
          confirmButtonColor: "#00d0b0"
        })
      }
    }
  }

  const handleSubmit = () => {
    if (state.faceNarration.enable) {
      if (state.faceNarration.media) {
        if (state.faceNarration.coverage) {
          setNextLoader(true)
          dispatch(onUpdateCampaign(state, navigate, setNextLoader))
        } else {
          dispatch(setAlert("Please select coverage to continue.", "danger"))
        }
      }
      else {
        dispatch(setAlert("Please add media to continue.", "danger"))
      }
    } else {
      setNextLoader(true)
      dispatch(onUpdateCampaign(state, navigate, setNextLoader))
    }

  }

  const fetchCampaign = () => {
    const data = {
      id: cId
    }
    dispatch(onFetchCampaign(data, setState))
  }

  useEffect(() => {
    if (cId) {
      fetchCampaign()
    }
  }, [cId])
  return (
    <>
      <Header />
      <div className="site-wrap">
        <div className="site-container">
          <div className="create-cont" >
            <h2>Talking Head</h2>

            <div className="uploadList">
              <div className="row">
                <div className='col-lg-12 mb-3'>
                  <div className="opt-select">
                    <span className=''>Add your face to the video</span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name='enable'
                        checked={state.faceNarration.enable}
                        onChange={handleChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>

                  <hr />
                </div>
                {state.faceNarration.enable ?
                  <>
                    <div className="Face_narration">
                      <h2>Instructions for adding talking head</h2>
                      {/* <h2>Do's and Don't Checklist</h2> */}
                      <div className="col-lg-12 col-12" style={{ margin: "0 auto" }}>
                        <div className="Face_narration_section">
                          <ul className='Face_narration_do'>
                            <div className='Thumb'>  <img src={thumb} alt="Thumb" /> <span>The Do's</span></div>
                            <li> <div><AiOutlineCheck className="green" /></div><span>Use a single face in the frame  </span></li>
                            <li> <div><AiOutlineCheck className="green" /></div><span>Opt for a close-up shot of your full face.</span></li>
                            <li> <div><AiOutlineCheck className="green" /></div><span>Position your face in the top half of the frame.</span></li>
                            <li> <div><AiOutlineCheck className="green" /></div><span>Ensure the image is in HD quality.</span></li>
                            <li> <div><AiOutlineCheck className="green" /></div><span>Choose a solid, clean background.</span></li>
                            <li> <div><AiOutlineCheck className="green" /></div><span>Make sure the lighting is bright and even.</span></li>
                            <li> <div><AiOutlineCheck className="green" /></div><span>Make sure the face is of a human being.</span></li>
                            <div className="Face_narration_img">
                              <img src={face} alt="" />
                            </div>
                          </ul>

                          <ul className='Face_narration_dont'>
                            <div className='Thumbs'>   <img src={unthumb} alt="" />  <span>The Don'ts</span></div>
                            <li> <div><AiOutlineCheck className="red" /></div><span>Avoid using multiple faces in one image.</span></li>
                            <li> <div><AiOutlineCheck className="red" /></div><span>Do not use distant or partial face shots.</span></li>
                            <li> <div><AiOutlineCheck className="red" /></div><span>Keep your face away from the bottom of the frame.</span></li>
                            <li> <div><AiOutlineCheck className="red" /></div><span>Don't use blurry or low-quality pictures.</span></li>
                            <li> <div><AiOutlineCheck className="red" /></div><span>Refrain from using busy or patterned backgrounds.</span></li>
                            <li> <div><AiOutlineCheck className="red" /></div><span>Stay away from dimly lit or shadowy lighting.</span></li>
                            <li> <div><AiOutlineCheck className="red" /></div><span>Do not use face of any pet or any other animal.</span></li>
                            
                            <div className="Face_narration_img">
                              <img src={face2} alt="" />
                            </div>
                          </ul>
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-12 mb-3">
                      <div className="uploaded-images d-flex justify-content-center mb-3">
                        {state.faceNarration.media.url ?
                          <div className="upload-image-single-narration">
                            <img alt="" src={state.faceNarration.media.url} style={{ objectFit: "contain", height: "100%" }} />
                          </div> : null}
                      </div>
                      <div className="uploadList-left">
                        <div className="upload">
                          {loader.upload ?
                            <> {percent}%</> :
                            <>
                              <FiUpload />
                              <p> Upload Image</p>
                              <input
                                type="file"
                                style={{ cursor: "pointer" }}
                                onChange={onInputFile}
                              />
                            </>}
                        </div>
                      </div>

                      <div className="inpx-wrap">
                        <label className="pb-2 d-flex" htmlFor="headMovement" style={{ cursor: "pointer" }}>
                          <input
                            type='checkbox'
                            name='headMovement'
                            id='headMovement'
                            onChange={handleChange}
                            className='mx-2'
                            checked={state.faceNarration.headMovement}
                            style={{ height: 20, width: 20 }}
                          />
                          Include head movement.</label>

                      </div>

                      <div className="inpx-wrap">
                        <div>
                          <label className="pb-2" htmlFor="">Coverage</label>
                          <select
                            className="inpx"
                            name="coverage"
                            value={state.faceNarration.coverage}
                            style={{ borderColor: "#00D0B0" }}
                            onChange={handleChange}
                          >
                            <option value={""}>Select Coverage</option>
                            <option value={10}>10%</option>
                            <option value={25}>25%</option>
                            <option value={50}>50%</option>
                          </select>
                          {state.faceNarration.coverage !== "" ?
                            <div className={`alert alert-${freqMessage.color} mt-2 text-center `} role="alert">
                              {freqMessage.message}
                            </div>
                            : null}
                        </div>
                      </div>
                    </div>
                  </> : null}
              </div>

            </div>
            <div className="inpx-wrap text-end">
              <button className="theme-btn large btn-min bdr" type="button" disabled={loader.upload} style={{ background: loader.upload ? "gray" : "" }} onClick={() => navigate(`/templates?cid=${cId}`)}><span><BsArrowLeft /> Back</span></button>
              <button className="theme-btn large btn-min ms-2" type="button" disabled={loader.upload} style={{ background: loader.upload ? "gray" : "" }} onClick={handleSubmit}><span>{nextLoader ? <> Next <FaSpinner className="spin" /></> : <> Next <BsArrowRight /></>}</span></button>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default FaceNarration


