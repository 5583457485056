import React from 'react'
import { Modal } from 'react-bootstrap'
import CreateVideo from '../../Dashboard/CreateVideo'
import { AiOutlineClose } from 'react-icons/ai'

const CreateContentModal = ({ show, handleClose }) => {
    return (
        <Modal className="theme-modal create-modal px-2 " show={show} onHide={handleClose} centered>
            <Modal.Body>
                <span className="modal-close" onClick={handleClose}><AiOutlineClose /></span>
                <div style={{ marginTop: 100 }}>
                    <CreateVideo />
                </div>
                <div className="group-btn">
                    <div className="row">
                        <div className="col-12 text-end">
                            <button className="theme-btn  bdr" style={{ cursor: "pointer" }} type='button' onClick={handleClose}><span>Cancel</span></button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CreateContentModal