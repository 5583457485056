import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { onUploadIR, removeIO, updateIOStatus } from '../../../../Redux/Actions/VideoActions';
import { FiUpload } from 'react-icons/fi';
import dummyImage from "../../../../assets/images/dummy.jpg"

const IntroOutro = (props) => {
    const dispatch = useDispatch()
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState(false)
    const [ir, setIr] = useState("")

    const handleStatus = (e) => {
        dispatch(updateIOStatus(e.target.checked, props.type))
        if (e.target.checked === false) {
            dispatch(removeIO(props.type))
        }
    }

    const onInputVideo = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].type === "video/mp4") {
                const allowedSize = 20000000;
                if (e.target.files[0].size < allowedSize) {

                    const formData = new FormData();
                    setLoader(true)
                    formData.append('file', e.target.files[0])
                    formData.append('upload_type', props.type)
                    dispatch(onUploadIR(formData, setLoader, props.type, setPercent))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for video is 20MB!',
                        confirmButtonColor: "#00D0B0"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Upload ${props.type.charAt(0).toUpperCase() + props.type.slice(1)} Video!`,
                    confirmButtonColor: "#00D0B0"
                })
            }
        }
    }

    return (
        <>
            <div className="opt-select">
                <span className='text-capitalize'>{props.type}</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        id={props.type}
                        checked={props.data.enable}
                        onChange={(e) => handleStatus(e)}
                        name={props.type}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
            {props.data.enable ?
                <div className="upload mt-3">
                    {loader ?
                        <div className='percent-loader-box'>
                            <span className='loader-percent'>{percent}%</span>
                        </div> :
                        <div>
                            <div className="d-flex justify-content-evenly align-items-center" style={{ width: "100%" }}>
                                <div>
                                    <FiUpload />
                                    <p className='text-capitalize'>Upload {props.type}</p>
                                    <input
                                        type="file"
                                        accept="video/*"
                                        onChange={(e) => onInputVideo(e)}
                                    />
                                </div>
                                <div className="up_img">
                                    <img style={{ objectFit: 'contain', height: 82 }} src={props.data.thumbnail ? props.data.thumbnail : dummyImage} alt="" />
                                </div>
                            </div>
                            {/* <div style={{ width: "100%", height: 20, padding: 10 }} className='upload mt-3'>
                                <span style={{ background: "#000", padding: "5px 10px", borderRadius: 10, fontSize: 12 }}>Select Intro Video + </span>
                            </div> */}
                        </div>
                    }
                </div>
                : ""}
        </>
    );
}

export default IntroOutro;
