import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { onCreateCampaign, onUpdateCampaign } from '../../../Redux/Actions/VideoActions'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { BsArrowRight } from 'react-icons/bs'

const DimensionModal = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const onChooseDimension = (val) => {
        props.setState((draft) => {
            draft.dimension = val
        })
    }

    const handleSubmitCamp = () => {
        setLoader(true)
        if (props.state.id) {
            dispatch(onUpdateCampaign(props.state, navigate, setLoader, "dimension"))
        }
        else {
            dispatch(onCreateCampaign(props.state, navigate, setLoader))
        }
    }

    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <span className="modal-close" onClick={props.handleClose}><AiOutlineClose /></span>
                <div className="connect-modal">
                    <h2>Choose a video dimension</h2>
                    <div className="row">
                        <div className="col-4">
                            <div className={`dimension ${props.state.dimension === "9x16" ? "select" : ""}`} style={{ cursor: "pointer" }} onClick={() => onChooseDimension("9x16")}>
                                <h5>9:16</h5>
                                <p>Story</p>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className={`dimension ${props.state.dimension === "16x9" ? "select" : ""}`} style={{ cursor: "pointer" }} onClick={() => onChooseDimension("16x9")}>
                                <h5>16:9</h5>
                                <p>Regular</p>
                            </div>
                        </div>
                        {/* <div className="col-6">
                            <div className="dimension">
                                <h5>1:1</h5>
                                <p>Square</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="group-btn">
                    <div className="row">
                        <div className="col-6">
                            <button className="theme-btn full bdr" onClick={props.handleClose}><span>Cancel</span></button>
                        </div>
                        <div className="col-6">
                            <button className="theme-btn full" onClick={handleSubmitCamp}><span>Next {loader ? <FaSpinner className='spin' /> : <BsArrowRight />}</span></button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DimensionModal