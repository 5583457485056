import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { onFetchScript, onUpdateScript } from "../../../Redux/Actions/CommonActions";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import { FaSpinner } from "react-icons/fa"

const CreateScript = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cId = queryString.parse(location.search).cid

    const [scriptText, setScriptText] = useState("")
    const [dimension, setDimension] = useState("")
    const [loader, setLoader] = useState(true)
    const [characterLimit, setCharacterLimit] = useState({
        limit: 12000
    })
    const [buttonLoader, setButtonLoader] = useState({
        save: false,
        next: false
    })


    const handleSubmit = (type) => {
        if (scriptText) {
            let data = {
                cId: cId,
                savedScript: scriptText,
                type: type
            }
            setButtonLoader({
                ...buttonLoader,
                next: type === "save" ? false : true,
                save: type === "save" ? true : false
            })
            dispatch(onUpdateScript(data, navigate, dimension, buttonLoader, setButtonLoader))
        } else {
            dispatch(setAlert("Please provide script!", "danger"))
        }
    }

    const fetchVideo = () => {
        let data = {
            cId: cId
        }
        dispatch(onFetchScript(data, setScriptText, setDimension, setLoader))
    }

    useEffect(() => {
        if (cId) {
            fetchVideo()
        }
    }, [cId])
    return (
        loader ? <>
            <div className="loader-sec">
                <div className="loader" />
            </div>
        </> :
            <>
                <Header />
                <div className="site-wrap">

                    <div className="project-search-wrap">
                        <div className="site-container">
                            <div className="project-search">
                                <div className="project-search-in">
                                    <div className="row align-items-center">
                                        <div className="col-lg-8">
                                            <div className="project-search-single">
                                                <h2>Review Script</h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="project-search-single">
                                                <div className="inpx-wrap">
                                                    <button
                                                        disabled={scriptText.length > characterLimit.limit}
                                                        className="theme-btn bdr full"
                                                        onClick={() => handleSubmit("save")}
                                                        style={scriptText.length > characterLimit.limit ? { background: "gray", borderColor: "gray" } : {}}
                                                    >
                                                        <span>{buttonLoader.save ? <>Saving <FaSpinner className="spin" /></> : "Save Script"}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="project-search-single">
                                                <div className="inpx-wrap">
                                                    <button
                                                        disabled={scriptText.length > characterLimit.limit}
                                                        className="theme-btn full"
                                                        onClick={() => handleSubmit("createVideo")}
                                                        style={scriptText.length > characterLimit.limit ? { background: "gray" } : {}}
                                                    >
                                                        <span>{buttonLoader.next ? <>Converting <FaSpinner className="spin" /></> : "Convert to Video "}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="site-container">
                        <hr />

                        <div className="script-wrap">
                            <div className="script-outer">
                                <span className="d-flex justify-content-end pe-2" style={{ fontSize: 11, width: "100%" }}>Characters: {scriptText.length} </span>

                                <div
                                    className="script-in"
                                    style={scriptText.length > characterLimit.limit ? { borderColor: "crimson" } : {}}
                                >
                                    {/* <div className="script-top">
                                        <p>To separate slides use <span>(.Dot)</span></p>
                                    </div> */}
                                    <div className="script-mid">
                                        <textarea
                                            className="inpx large"
                                            value={scriptText}
                                            onChange={(e) => setScriptText(e.target.value)}
                                        />
                                    </div>
                                    <div
                                        className="script-bottom text-end"
                                        style={scriptText.length > characterLimit.limit ? { borderColor: "crimson" } : {}}
                                    >
                                        <p>Character Limit : {characterLimit.limit}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
    )
}

export default CreateScript;