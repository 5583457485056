import React from "react";
import { AiOutlineEyeInvisible } from "react-icons/ai"
import { Link } from "react-router-dom";
import LoginHeader from "../../Header/LoginHeader";
import Footer from "../../Footer/Footer";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { forgetPassword } from "../../../Redux/Actions/AuthActions";
import TitleBar from "../../CommonComponents/TitleBar";
import { FaSpinner } from "react-icons/fa";

const ForgotPasword = () => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        setLoader(true)
        dispatch(forgetPassword(data, setLoader))
    }

    return (
        <>
            <TitleBar title="Forgot Password" /> 
            <div className="login-header">
                <LoginHeader />
            </div>
            <div className="login-wrap">

                <div className="login-left">
                    <div className="welcome-txt">
                    <h2><span>Welcome To </span>ClipsReelAI</h2>
                        <p>Instantly turn any webpage into a traffic-getting video at the push of a button!</p>
                        <h3>..it's Super Easy-to-use</h3>
                    </div>
                </div>

                <div className="login-right">
                    <div className="login-right-main">
                    <h2>Enter Your <span>Email Address</span></h2>
                    <form className="login-form" onSubmit={handleSubmit}>
                        <div className="input-wrap">
                            <label htmlFor="">Email Address</label>
                            <div className="inp-outer">
                                <input
                                    className="inp"
                                    type="email"
                                    placeholder='Email Address'
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                        </div>

                        <div className="input-wrap">
                            <button className="theme-btn full" type="submit"><span>{loader ? <>Submitting <FaSpinner className="spin" /> </> : "Submit"}</span></button>
                        </div>
                    </form>
                    <div className="log-option text-center">Remember? <Link to="/login"> Sign in</Link></div>
                    </div>
                    
                </div>
                
            </div>

            <div className="login-footer">
                <Footer />
            </div>
        </>
    )
}

export default ForgotPasword;