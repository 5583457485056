import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onFetchSonority } from '../../../../Redux/Actions/VideoActions';
import AudioTrack from '../../../CommonComponents/AudioTrack';
import { FaSpinner } from 'react-icons/fa';
import SweetAlert from '../../../CommonComponents/SweetAlert';

const Sonority = ({ active, onSelectAudio, selectedAudio, setSelectedAudio }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const socialData = useSelector(state => state.social.sonority);

    const [loader, setLoader] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [musicData, setMusicData] = useState(false);
    const [currentUrl, setCurrentUrl] = useState('');

    const [sweet, setSeweet] = useState({
        enable: false
    })

    const connectSonority = () => {
        setSeweet({
            ...sweet,
            enable: true
        });
    }

    const onCancle = () => {
        setSeweet({
            ...sweet,
            enable: false
        });
    }
    const onConfirm = () => {
        navigate('/integration')
    }

    const fetchSonorityMedia = () => {
        setLoader(true);
        dispatch(onFetchSonority(setMusicData, setLoader, socialData[0].token))
    }

    useEffect(() => {
        if (active.sonority) {
            if (socialData.length > 0) {
                fetchSonorityMedia();
            }
            return () => {
                setMusicData(false);
            }
        }
    }, [active.sonority])

    return (
        <div className="audio-scroll">
            <div className="audio-list">
                {
                    socialData.length > 0 ? <div className="alert alert-warning text-center mt-2" role="alert">
                        This music will apply to the current slide
                    </div>
                        : <div className="alert alert-warning text-center" role="alert">
                            You haven't connected your Sonority account yet. Click <a className="custom-anchor" style={{ cursor: "pointer" }} onClick={() => connectSonority()}>Here</a> to connect and use those audios
                        </div>
                }
                {
                    loader ?
                        <div className="col-md-12 mt-3 text-center">
                            <FaSpinner className='spin' style={{ color: '#00D0B0', fontSize: '25px' }} />
                        </div>
                        : ''
                }
                {
                    loader ? '' : musicData !== false ? <h6 className="py-2" style={{ fontSize: 13 }}>Audio Tracks</h6> : ''
                }
                {
                    musicData !== false ?
                        musicData.merge.map((item, index) => {
                            return (
                                <AudioTrack
                                    name={item.name.length > 20 ? `${item.name.slice(0, 19)}...` : item.name}
                                    url={item.output_audio}
                                    selectedAudio={selectedAudio}
                                    setSelectedAudio={setSelectedAudio}
                                    onSelectAudio={onSelectAudio}
                                    key={index}
                                    id={item.id}
                                />
                            )
                        })
                        : ''
                }

                {
                    loader ? '' : musicData !== false ? <h6 className="py-2" style={{ fontSize: 13 }}>Music Tracks</h6> : ''
                }
                {
                    musicData !== false ?
                        musicData.music.map((item, index) => {
                            return (
                                <AudioTrack
                                    name={item.title.length > 20 ? `${item.title.slice(0, 19)}...` : item.title}
                                    url={item.output_audio}
                                    selectedAudio={selectedAudio}
                                    setSelectedAudio={setSelectedAudio}
                                    onSelectAudio={onSelectAudio}
                                    key={index}
                                    id={item.id}
                                />
                            )
                        })
                        : ''
                }
                {
                    loader ? '' : musicData !== false ? <h6 className="py-2" style={{ fontSize: 13 }}>Voice Tracks</h6> : ''
                }
                {
                    musicData !== false ?
                        musicData.tts.map((item, index) => {
                            return (
                                <AudioTrack
                                    name={item.title.length > 20 ? `${item.title.slice(0, 19)}...` : item.title}
                                    url={item.output_audio}
                                    selectedAudio={selectedAudio}
                                    setSelectedAudio={setSelectedAudio}
                                    onSelectAudio={onSelectAudio}
                                    key={index}
                                    id={item.id}
                                />

                            )
                        })
                        : ''
                }
            </div>

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure? You would be redirect to the integration page."}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={"Yes"}
                cancelButtonName={"Cancel"}
                handleClose={onCancle}
                performDelete={onConfirm}


            />
        </div>
    )
}

export default Sonority