import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi"
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import TitleBar from "../../CommonComponents/TitleBar";
import VideoProjects from "../../CommonComponents/VideoProjects";
import CreateContentModal from "./CreateContentModal";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import MergeProjects from "./MergeProjects";
import { useSelector } from "react-redux";

const Projects = () => {
    const location = useLocation()
    const auth = useSelector(state => state.auth)
    const { d, p } = queryString.parse(location.search)
    const [q, setQ] = useState("")
    const [type, setType] = useState(1)
    const [dimension, setDimension] = useState("")

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    useEffect(() => {
        if (d) {
            if (d === "hz") {
                setDimension("16x9")
            } else {
                setDimension("9x16")
            }
        }
    }, [d])

    useEffect(() => {
        if (p) {
            if (+p === 1) {
                setType(1)
            } else {
                setType(2)
            }
        } else {
            setType(1)
        }
    }, [p])



    return (
        <>
            <Header />
            <TitleBar title="Projects" />
            <div className="site-wrap">

                <div className="project-search-wrap">
                    <div className="site-container">
                        <div className="project-search">
                            <h2>All Projects</h2>
                            <div className="project-search-in">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="project-search-single">
                                            <div className="inpx-wrap icon-left">
                                                <input
                                                    className="inpx"
                                                    type="text"
                                                    onChange={(e) => setQ(e.target.value)}
                                                    value={q}
                                                    placeholder="Search"
                                                />
                                                <span className="inpx-icon"><BiSearch /></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="project-search-single">
                                            <div className="inpx-wrap">
                                                <select
                                                    className="inpx"
                                                    onChange={(e) => setDimension(e.target.value)}
                                                    value={dimension}
                                                >
                                                    <option value={""}>All</option>
                                                    <option value={"16x9"}>16:9</option>
                                                    <option value={"9x16"}>9:16</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-2">
                                        <div className="project-search-single">
                                            <div className="inpx-wrap">
                                                <select
                                                    className="inpx"
                                                    value={type}
                                                    onChange={(e) => setType(e.target.value)}
                                                >
                                                    <option value={1}>Projects</option>
                                                    <option value={2}>Reelmerge Projects</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-2">
                                        {+auth.user.isClientAccount === 1 ? "" :
                                            <div className="project-search-single">
                                                <div className="inpx-wrap">
                                                    <button className="theme-btn full" onClick={handleShow}><span>+ Create New</span></button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="video-project mt-4">
                    {+type === 1 ?
                        <VideoProjects
                            isDash={false}
                            q={q}
                            dimension={dimension}
                        /> :
                        <MergeProjects
                            q={q}
                            dimension={dimension}
                        />
                    }
                </div>

            </div>
            <CreateContentModal
                show={show}
                handleClose={handleClose}
            />

            <Footer />
        </>
    )
}

export default Projects;