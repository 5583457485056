import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import dummy16x9 from "../../../assets/images/thumb_16x9.png"
import dummy9x16 from "../../../assets/images/thumb_9x16.png"
import { BsBoxArrowInDown, BsChevronDown } from 'react-icons/bs'
import { BiChevronDownSquare, BiTrash } from 'react-icons/bi'
import { MdOutlineEdit } from 'react-icons/md'
import { Link } from 'react-router-dom'
import PublishModal from './PublishModal'
import SweetAlert from '../../CommonComponents/SweetAlert'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

const MergeCards = ({ curElem, videos, setVideos }) => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancel = () => {
        setSweet({
            enable: false,
        })
    }

    const onConfirm = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-merge", data, videos, setVideos, setSweet))
    }


    const handleDownload = () => {
        let a = document.createElement('a')
        a.href = curElem.output_video
        a.download = `${curElem.name}.mp4`
        a.target = "_blank"
        a.click()
    }



    return (
        <div className={`col-xl-${4} col-lg-6`}>
            <div className={
                curElem.ratio === "16:9" ? "video-project-16x9" : 'video-project-9x16'
            }>
                <div className={
                    curElem.ratio === "16:9" ? 'video-project-img_16x9' : 'video-project-img_9x16'
                }>
                    <img alt=""
                        src={curElem.output_video ? curElem.preview_image : curElem.ratio === "16:9" ? dummy16x9 : dummy9x16}
                        style={{ height: "100%", width: "100%" }}
                    />
                </div>
                <div className="Video_Project_Render">
                    <div className="progress" style={{ height: 22 }}>
                        <div className='non-rendering-bar' style={curElem.status === "Failed" ? { background: "#DC143C" } : {}}>{curElem.status}</div>
                    </div>

                </div>
                <div className="video-project-bottom">
                    <div>
                        <span style={{ fontSize: '15px' }} >{curElem.name}</span>
                        <p>{curElem.created.split(" ")[0]}</p>
                    </div>
                    <div className="drop-style-1">
                        <Dropdown>
                            <Dropdown.Toggle variant="r" id="dropdown-basic">
                                <BsChevronDown />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul>
                                    {curElem.output_video ?
                                        <>
                                            <li><Link onClick={handleDownload}><BsBoxArrowInDown /> Download</Link></li>
                                            <li><Link onClick={handleShow}><BiChevronDownSquare /> Publish</Link></li>
                                        </> : null}
                                    {/* <li ><Link ><MdOutlineEdit /> Edit</Link></li> */}
                                    {+auth.user.isClientAccount ? "" :
                                        <li ><Link onClick={onDelete}><BiTrash /> Delete</Link></li>
                                    }
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            <PublishModal
                curElem={curElem}
                show={show}
                handleClose={handleClose}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this project?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
            />
        </div>
    )
}

export default MergeCards