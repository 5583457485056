import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { BiSupport, BiLogOut, BiCopy } from "react-icons/bi"
import { MdLockOutline } from "react-icons/md"
import { AiOutlineSetting } from "react-icons/ai"
import { FiUser, FiUserPlus, FiStar } from "react-icons/fi"
import { useDispatch, useSelector } from "react-redux";
import LogoutModal from "../CommonComponents/LogoutModal";
import logo from "../../assets/images/logo.png"

const Header = () => {

    const rebrand = useSelector(state => state.rebrand)
    const auth = useSelector(state => state.auth)
    const [scroll, setScroll] = useState(false);
    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(!show2);

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])

    return (
        <>
            <header className={scroll ? "site-header fixed" : "site-header"}>
                <div className="header-in">
                    <div className="site-header-main">
                        <div className="logo">
                            <NavLink className="logo" to="/dashboard">
                                <img alt="" src={rebrand.data ? rebrand.data.logo : logo} />
                            </NavLink>
                        </div>
                        <div className="site-nav" style={{ left: show2 ? 0 : "-300px" }} >

                            <ul>
                                <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                                <li><NavLink to="/projects">Projects</NavLink></li>
                                {+auth.user.isClientAccount === 1 ? "" :
                                    <li><NavLink to="/integration">Integration</NavLink></li>}
                                <li><NavLink to="/help">Help</NavLink></li>
                            </ul>
                        </div>

                        <div className="profile-link">

                            <div className="menu_bar me-2" onClick={handleClose2}>
                                <i className="fa-solid fa-bars" style={{ fontSize: "18px" }}></i>
                            </div>
                            <div className="user-nav">
                                <Dropdown>
                                    <Dropdown.Toggle variant="n" id="dropdown-basic">
                                        <div className="user-img">
                                            <div className="user-img-in"><img alt="" src={auth.user.profile} /></div>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <ul>
                                            <li><Link to="/my-account"><span><FiUser /></span> My Account</Link></li>
                                            {+auth.user.isClientAccount === 1 ? "" :
                                                <>
                                                    {+auth.user.isVirtualAccount === 1 ? "" :
                                                        <>
                                                            <li><Link to="/privacy"><span><MdLockOutline /></span> Privacy</Link></li>
                                                            {memberShip.includes("enterprise") ||
                                                                memberShip.includes("fastpass") ||
                                                                memberShip.includes("bundle") ?
                                                                <>
                                                                    <li><Link to="/account-management"><span><FiUserPlus /></span> Account Management</Link></li>
                                                                    <li><Link to="/settings"><span><AiOutlineSetting /></span> Settings</Link></li>
                                                                </>
                                                                : null}
                                                        </>}
                                                    <li><Link to="/upgrade"><span><FiStar /></span> Upgrade</Link></li>
                                                    {memberShip.includes("professional") ||
                                                        memberShip.includes("professional-lite") ||
                                                        memberShip.includes("fastpass") ||
                                                        memberShip.includes("bundle") ?
                                                        <li><Link to="/reel-merge"><span><BiCopy /></span> Reelmerge</Link></li>

                                                        : null}

                                                </>
                                            }



                                            <li><Link to="/help"><span><BiSupport /></span> Help</Link></li>
                                            <li><Link onClick={handleShow}><span><BiLogOut /></span> Logout</Link></li>
                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>

                        <LogoutModal
                            show={show}
                            handleClose={handleClose}
                        />

                    </div>
                </div>
            </header>


        </>
    )
}

export default Header;