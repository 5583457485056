import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { BsArrowRight } from 'react-icons/bs'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { onCreateCampaign } from '../../Redux/Actions/VideoActions'

const CreateUrlModal = ({ show, handleClose, modalData }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        name: "",
        type_url: "",
        type: "",
    })

    const [isEnter, setIsEnter] = useState({
        in: false,
        out: false
    })
    const scriptStatus = ["Visiting URL", "Reading page's content", "Analysing content", "Extracting content"]
    const [staText, setStaText] = useState("")


    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let data = {
            ...state,
            type: modalData.isBlog ? "blog" : "ecommerce"
        }
        dispatch(onCreateCampaign(data, navigate, setLoader))
    }

    useEffect(() => {
        if (loader) {
            let ind = 0
            setStaText(scriptStatus[0])
            const interval = setInterval(() => {
                ind++
                if (ind === 4) {
                    ind = 1
                }
                setTimeout(() => {
                    setIsEnter({
                        ...isEnter,
                        in: false,
                        out: true
                    })
                    setTimeout(() => {
                        setStaText(scriptStatus[ind])
                    }, 1000)
                }, 1000)
            }, 7000)

            return () => {
                clearInterval(interval)
            }
        }

    }, [loader])

    useEffect(() => {
        if (staText) {
            setIsEnter({
                ...isEnter,
                in: true,
                out: false
            })
        }
    }, [staText])


    useEffect(() => {
        if (!show) {
            return () => {
                setState({
                    name: "",
                    type_url: "",
                    type: ""
                })
                setLoader(false)
                setStaText("")
            }
        }
    }, [show])

    const dummyFunction = () => {

    }

    return (
        <Modal className="theme-modal create-url-modal" show={show} onHide={loader ? dummyFunction : handleClose} centered>
            <Modal.Body >
                <form onSubmit={handleSubmit}>
                    <span className="modal-close" onClick={loader ? dummyFunction : handleClose}><AiOutlineClose /></span>
                    <div className="connect-modal">
                        <h2>{modalData.name}</h2>

                        <div className="input-wrap">
                            <label htmlFor="">Project Name</label>
                            <div className="input-outer">
                                <input
                                    className="common-inp"
                                    type="text"
                                    name='name'
                                    placeholder="Enter Project Name Here"
                                    value={state.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="input-wrap">
                            <label htmlFor="">Enter URL</label>
                            <div className="input-outer">
                                <input
                                    className="common-inp"
                                    type="url"
                                    placeholder="Enter URL Here"
                                    name='type_url'
                                    value={state.type_url}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="group-btn">
                        <div className="row">
                            <div className="col-6">
                                <button className="theme-btn full bdr" style={loader ? { background: "gray", borderColor: "gray" } : {}} disabled={loader} type="button" onClick={handleClose}><span>Cancel</span></button>
                            </div>
                            <div className="col-6">
                                <button className="theme-btn full" type='submit' disabled={loader}><span>Next {loader ? <FaSpinner className="spin" /> : <BsArrowRight />}</span></button>
                            </div>
                        </div>
                    </div>
                </form>

            </Modal.Body>
            <div className="swiper_text">
                <div className='text-center mb-5'>
                    {loader && staText ?
                        <p className={`animate__animated ${isEnter.in ? 'animate__lightSpeedInLeft' : ""} ${isEnter.out ? 'animate__lightSpeedOutRight' : ""}`}>{staText}...</p> : ""}
                </div>
            </div>
        </Modal>
    )
}

export default CreateUrlModal