import React, { useEffect, useState } from 'react'

import { fetchData } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import MergeCards from './MergeCards'
import { FaSpinner } from 'react-icons/fa'

const MergeProjects = (props) => {

    const dispatch = useDispatch()
    const [videos, setVideos] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })

    const fetchMerge = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchData("fetch-merge-campaigns", {}, setVideos, loader, setLoader))
    }

    useEffect(() => {
        fetchMerge()
    }, [])

    return (
        <div className="video-project-wrap">
            <div className="row ">
                {videos.length > 0 ?
                    videos.filter((ele) => {
                        return props.dimension.replace("x", ":") === ele.ratio || props.dimension === ""
                    }).filter((proj) => {
                        if (props.q !== undefined) {
                            return proj.name.toLowerCase().includes(props.q.toLowerCase())
                        } else {
                            return proj
                        }
                    }).map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <MergeCards
                                    curElem={curElem}
                                    videos={videos}
                                    setVideos={setVideos}
                                />
                            </React.Fragment>
                        )
                    })
                    :
                    <div className="col-md-12">
                        <h4 className="text-center load-icon-help mt-3">
                            {
                                loader.fetch ?
                                    <FaSpinner className="spin" style={{ fontSize: 25, color: "#00c4a6" }} /> :
                                    <p style={{ fontSize: 18 }}>
                                        You do not have any "Reelmerge Projects" in your account.
                                    </p>
                            }
                        </h4>
                    </div>

                }

            </div>
        </div>
    )
}

export default MergeProjects