import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { logoutUser } from '../../Redux/Actions/AuthActions';
import { useDispatch } from 'react-redux';

const LogoutModal = (props) => {
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(logoutUser())
    }
    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div className="connect-modal">
                    <h2>Log Out</h2>
                    <div className="input-wrap text-center mt-5">
                        <img alt="" src={require('../../assets/images/log-out.png')} />
                        <h5 className="pt-3">Are you sure you want to log out?</h5>
                    </div>
                </div>
                <div className="group-btn">
                    <div className="row">
                        <div className="col-6">
                            <button className="theme-btn full bdr" onClick={props.handleClose}><span>No</span></button>
                        </div>
                        <div className="col-6">
                            <button className="theme-btn full" onClick={handleLogout}><span>Yes</span></button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LogoutModal