import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import DimensionModal from "./DimensionModal";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchData, onGenerateScript, onUploadMedia } from "../../../Redux/Actions/CommonActions";
import { useDispatch } from "react-redux";
import queryString from "query-string"
import { onFetchCampaign } from "../../../Redux/Actions/VideoActions";
import { FiUpload } from 'react-icons/fi'
import Swal from "sweetalert2";
import { RxCross2 } from "react-icons/rx"
import { useImmer } from "use-immer";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";

const CreateContent = () => {

    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const cId = queryString.parse(location.search).cid
    const charLimit = 25000
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isEnter, setIsEnter] = useState({
        in: false,
        out: false
    })
    const scriptStatus = ["Visiting URL", "Reading page's content", "Analysing content", "Extracting content"]
    const [staText, setStaText] = useState("")

    const [language, setLanguage] = useState([])
    const [country, setCountry] = useState([])
    const [geneLoader, setGeneLoader] = useState(false)
    const [loader, setLoader] = useState({
        upload: false
    })
    const [percent, setPercent] = useState(0)
    const [state, setState] = useImmer({
        id: false,
        name: "",
        preScript: "",
        dimension: "16x9",
        language: "English",
        country: "United States",
        languageCode: "",
        style: "engaging",
        type: "copy",
        media: []
    })



    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "language") {
            const lang = language.find(({ name }) => name === value)
            if (+lang.polly_support === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Warning...',
                    text: `We do not have AI voice for this language, thus the video generated will not have any voiceover.`,
                    confirmButtonColor: "#00D0B0"
                })
            }
            setState((draft) => {
                draft.language = value
            })
        } else {
            setState((draft) => {
                draft[name] = value
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let count = country.find(({ country_name }) => country_name === state.country)
        let lang = language.find(({ name }) => name === state.language)
        setState((draft) => {
            draft.languageCode = `${lang.code}-${count.code}`
        })

        handleShow()
    }
    const onSuccessUpload = (path, type, thumb) => {
        if (type === "image") {
            const img = new Image()
            img.onload = function () {
                let obj = {
                    url: path,
                    height: this.height,
                    width: this.width,
                    type: type
                }
                setState((draft) => {
                    draft.media = draft.media.concat(obj)
                })
            }
            img.src = path
        } else {
            let obj = {
                url: path,
                type: type,
                thumb: thumb
            }
            setState((draft) => {
                draft.media = draft.media.concat(obj)
            })
        }
    }

    const handleRemoveMedia = (index) => {
        setState((draft) => {
            draft.media.splice(index, 1)
        })
    }

    const onInputFile = (e) => {
        if (e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
                let fileSize = e.target.files[i].size
                if (allowedExt.includes(e.target.files[i].type)) {
                    if (fileSize < 5000000) {
                        const formData = new FormData()
                        formData.append('upload_type', "images")
                        formData.append('file', e.target.files[i])
                        dispatch(onUploadMedia(formData, onSuccessUpload, loader, setLoader, setPercent, "image"))
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Max allowed size for images is 5MB!',
                            confirmButtonColor: "#00d0b0"
                        })
                    }
                }
                else if (e.target.files[i].type === "video/mp4") {
                    if (fileSize < 20000000) {
                        const formData = new FormData()
                        formData.append('upload_type', "video")
                        formData.append('file', e.target.files[i])
                        dispatch(onUploadMedia(formData, onSuccessUpload, loader, setLoader, setPercent, "video"))
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Max allowed size for video is 20MB!',
                            confirmButtonColor: "#00d0b0"
                        })
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You have Selected Invalid File Type!',
                        confirmButtonColor: "#00d0b0"
                    })
                }
            }
        }
    }

    const handleGenerate = () => {
        setGeneLoader(true)
        let ind = 0
        setStaText(scriptStatus[0])
        const interval = setInterval(() => {
            ind++
            if (ind === 4) {
                ind = 1
            }
            setTimeout(() => {
                setIsEnter({
                    ...isEnter,
                    in: false,
                    out: true
                })
                setTimeout(() => {
                    setStaText(scriptStatus[ind])
                }, 1000)
            }, 1000)
        }, 7000)


        let data = {
            url: state.url,
            type: state.type,
            cId: state.id
        }

        dispatch(onGenerateScript(data, state, setState, setGeneLoader, interval))
    }


    const fetchLanguage = () => {
        dispatch(fetchData("fetch-google-language", {}, setLanguage, false, false, false, "shortDataLg"))
    }
    const fetchCountries = () => {
        dispatch(fetchData("countries", {}, setCountry, false, false, false, "shortDataCt"))
    }



    useEffect(() => {
        fetchCountries()
        fetchLanguage()
    }, [])

    const fetchCampaign = () => {
        const data = {
            id: cId
        }
        dispatch(onFetchCampaign(data, setState))
    }

    useEffect(() => {
        if (cId) {
            fetchCampaign()
        }
    }, [cId])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (staText) {
            setIsEnter({
                ...isEnter,
                in: true,
                out: false
            })
        }
    }, [staText])

    return (
        <>
            <Header />
            <div className="site-wrap">
                <div className="site-container">
                    <form className="create-cont" onSubmit={handleSubmit}>
                        <h2>Text to video</h2>
                        <hr />
                        <div className="inpx-wrap">
                            <label className="pb-2" htmlFor="">Proejct Name</label>
                            <input
                                className="inpx"
                                type="text"
                                name="name"
                                placeholder="Enter Project Name Here"
                                onChange={handleChange}
                                value={state.name}
                                required
                            />
                        </div>
                        <div className="inpx-wrap d-flex justify-content-between">
                            <div>
                                <label className="pb-2" htmlFor="">Country</label>

                                {/* <Select options={country} /> */}
                                <select
                                    className="inpx"
                                    name="country"
                                    onChange={handleChange}
                                    value={state.country}
                                    required
                                >
                                    <option value={""}>Select Country</option>
                                    {country.length > 0 ?
                                        country.map((curElem, index) => {
                                            return (
                                                <option key={index} value={curElem.country_name}>{curElem.country_name}</option>
                                            )
                                        })
                                        : ""}
                                </select>
                            </div>
                            <div className="mx-1">
                                <label className="pb-2" htmlFor="">Language</label>
                                <select
                                    className="inpx"
                                    name="language"
                                    onChange={handleChange}
                                    value={state.language}
                                    required
                                >
                                    <option value={""}>Select Language</option>
                                    {language.length > 0 ?
                                        language.filter((lang) => {
                                            return +lang.id !== 40
                                        }).map((curElem, index) => {
                                            return (
                                                <option key={index} value={curElem.name}>{curElem.name}</option>
                                            )
                                        })
                                        : ""}
                                </select>

                            </div>
                            <div>
                                <label className="pb-2" htmlFor="">Style</label>
                                <select
                                    className="inpx"
                                    name="style"
                                    onChange={handleChange}
                                    value={state.style}
                                    required
                                >
                                    <option value={""}>Select Style</option>
                                    <option value={"VSL"}>VSL</option>
                                    <option value={"engaging"}>Engaging</option>
                                    <option value={"promo"}>Promo</option>
                                    <option value={"informative"}>Informative</option>
                                    <option value={"fun"}>Fun</option>
                                    <option value={"news"}>News</option>
                                    <option value={"controversial"}>Controversial</option>
                                    <option value={"Product review"}>Product review</option>
                                </select>

                            </div>
                        </div>
                        {(state.type === "blog" || state.type === "ecommerce") ?
                            <>
                                <div className="inpx-wrap d-flex justify-content-between" >
                                    <input
                                        type="text"
                                        className="inpx"
                                        value={state.url}
                                        name="url"
                                        style={{ width: "92%" }}
                                        onChange={handleChange}
                                    />
                                    <button type="button" className="theme-btn ms-3" disabled={geneLoader} onClick={handleGenerate}><span className="">{geneLoader ? <>Retrying <FaSpinner className="spin" /> </> : "Retry"}</span></button>
                                </div>
                                {state.errorMessage ?
                                    <div className="inpx-wrap mt-2">
                                        <div className="alert alert-danger" role="alert">
                                            <span >{state.errorMessage}</span>
                                        </div>
                                    </div> : ""}
                                <div className="swiper_text">
                                    <div className='text-center mb-4'>
                                        {geneLoader && staText ?
                                            <p className={`animate__animated ${isEnter.in ? 'animate__lightSpeedInLeft' : ""} ${isEnter.out ? 'animate__lightSpeedOutRight' : ""}`}>{staText}...</p> : ""}
                                    </div>
                                </div>
                            </>
                            : ""}
                        <div className="inpx-wrap mt-2">
                            <span className="d-flex justify-content-end pe-2" style={{ fontSize: 11, width: "100%" }}>Characters: {state.preScript.length} </span>
                            <textarea
                                className="inpx large"
                                name="preScript"
                                type="text"
                                placeholder="Write or Copy Paste Your Content here"
                                onChange={handleChange}
                                value={state.preScript}
                                style={state.preScript.length > charLimit ? { borderColor: "red" } : {}}
                                required
                            />
                            {state.preScript.length > charLimit ?
                                <p style={{ color: "red", fontSize: 14 }} className="ps-2">Max Character limit is {charLimit}.</p> : null}
                            <div className="uploadList">
                                <div className="row">
                                    <h6 className="mb-2">Upload some media which should be included in the video.</h6>
                                    <div className="col-lg-12 mb-3">
                                        <div className="uploaded-images text-center">
                                            <ul>
                                                {state.media.length > 0 ?
                                                    state.media.map((curElem, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <div className="upload-image-single" style={{ position: "relative" }}>
                                                                    {curElem.type === "image" ?
                                                                        <>
                                                                            <img alt="" src={curElem.url} style={{ objectFit: "contain" }} />

                                                                        </> :
                                                                        <video
                                                                            src={curElem.url}
                                                                            style={{ height: "100%", width: "100%" }}
                                                                            autoPlay
                                                                            muted
                                                                        />
                                                                    }
                                                                    <span className="image-cancle-icon" onClick={() => handleRemoveMedia(index)}>
                                                                        <RxCross2 size={20} />
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    :
                                                    ""
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="uploadList-left">
                                            <div className="upload">
                                                {loader.upload ?
                                                    <> {percent}%</> :
                                                    <>
                                                        <FiUpload />
                                                        <p> Upload Media</p>
                                                        <input
                                                            type="file"
                                                            style={{ cursor: "pointer" }}
                                                            onChange={onInputFile}
                                                            multiple
                                                        />
                                                    </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="inpx-wrap text-end">
                            <button className="theme-btn large btn-min bdr" type="button" onClick={() => navigate('/dashboard')}><span><BsArrowLeft /> Back</span></button>
                            <button className="theme-btn large btn-min ms-2" type="submit" disabled={state.preScript.length > charLimit} style={{ background: state.preScript.length > charLimit ? "gray" : "" }}><span>Next <BsArrowRight /></span></button>
                        </div>
                    </form>
                </div>
            </div>
            <DimensionModal
                show={show}
                handleClose={handleClose}
                state={state}
                setState={setState}
            />
            <Footer />
        </>
    )
}

export default CreateContent;