import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { FiSearch } from 'react-icons/fi'
import { FiCrop } from 'react-icons/fi'
import { useDispatch } from 'react-redux';
import { fetchMedia } from '../../Redux/Actions/CommonActions';
import { useState } from 'react';
import HoverVideoPlayer from "react-hover-video-player";
import { GiCheckMark } from 'react-icons/gi';
import CropModal from './CropModal';
import { BiSearch } from 'react-icons/bi';
import { FaSpinner } from 'react-icons/fa';

const Library = ({ handleBackground, sType, type, checkMedia, setCheckMedia }) => {
    const dispatch = useDispatch()
    const [images, setImages] = useState([])
    const [error, setError] = useState("")
    const [cropData, setCropData] = useState({
        current: false,
        cropped: false
    })
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [loader, setLoader] = useState({
        fetch: false,
    })
    const [search, setSearch] = useState({
        key: "",
        page_number: 0,
    })
    const handleChange = (e) => {
        setSearch({
            ...search,
            key: e.target.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setImages([])
        setError("")
        fetchImages()
    }

    const handleSelectImage = (data) => {
        handleBackground(data, sType)
        setCheckMedia(data.url)
    }
    const fetchImages = (page = 1) => {
        let data = {
            keyword: search.key,
            page_number: page,
            search_type: sType
        }
        setSearch({
            ...search,
            page_number: page
        })
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchMedia("load-library-images", data, images, setImages, loader, setLoader, setError))
    }

    const selectCrop = (e, url) => {
        e.stopPropagation()
        handleShow();
        setCropData({
            ...cropData,
            current: url,
            cropped: false
        })
    }


    return (
        <div className='list-images mt-4'>
            <div className="project-search">
                <div className="project-search-in">
                    <form className="row" onSubmit={handleSubmit}>
                        <div className="col-lg-12">
                            <div className="project-search-single">
                                <div className="inpx-wrap mt-0 mb-2 icon-left">
                                    <input
                                        className="inpx"
                                        type="text"
                                        placeholder="Search"
                                        onChange={handleChange}
                                        value={search.key}
                                        required
                                    />
                                    <span className="inpx-icon"><BiSearch /></span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div>
                <InfiniteScroll
                    dataLength={images.length}
                    hasMore={true}
                    next={() => fetchImages(search.page_number + 1)}
                    scrollableTarget={`bgImagesScroll-${type}-${sType}`}
                    className='photo-scroll'
                >
                    <div className='photo-scroll' id={`bgImagesScroll-${type}-${sType}`}>
                        <div className="row sm"  >
                            {
                                images.length > 0 ?
                                    images.map((curElem, index) => {
                                        return (
                                            <div className="col-6" key={index} >
                                                <div className="photo-single" onClick={() => handleSelectImage(curElem, index)} style={{ position: "relative", cursor: "pointer", border: `${checkMedia === curElem.url ? 2 : 1}px solid #00D0B0`, borderRadius: "12px" }}>
                                                    <div style={{ zIndex: 2, position: "absolute", top: 4, left: 7, cursor: "pointer" }} onClick={(e) => selectCrop(e, curElem.url)}>
                                                        <FiCrop
                                                            color='#00c4a6'
                                                            size={18}
                                                        />
                                                    </div>
                                                    {checkMedia === curElem.url ?
                                                        <div className='select-tile-style' style={{ zIndex: 1, border: "none" }}>
                                                            <GiCheckMark />
                                                        </div> : ""}
                                                    {sType === "images" ?
                                                        <img alt="" src={curElem.thumbnail} />
                                                        :
                                                        <>
                                                            <HoverVideoPlayer
                                                                style={{ height: '100%' }}
                                                                videoSrc={curElem.url}
                                                                // pausedOverlay={
                                                                //     <img
                                                                //         src={curElem.thumbnail}
                                                                //         alt={`${search.keyword + ' ' + index}`}
                                                                //         style={{ padding: 2 }}
                                                                //     />
                                                                // }
                                                                loadingOverlay={
                                                                    <div style={{ color: "#00c4a6", display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
                                                                        <FaSpinner className="spin" size={20} />
                                                                    </div>
                                                                }
                                                            />
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }) : ""}

                            {
                                loader.fetch ? <div className='text-center mt-3'> <FaSpinner className="spin" style={{ fontSize: 25, color: "#00c4a6" }} /></div> : ""
                            }
                            {
                                error ? <div className='text-center mt-3' style={{fontSize:12}}><p>{error}</p></div> : ""
                            }
                        </div>
                    </div>
                </InfiniteScroll>
            </div >

            <CropModal
                show={show}
                handleClose={handleClose}
                type={sType}
                cropData={cropData}
                setCropData={setCropData}
                handleSelectImage={handleSelectImage}
            />
        </div >
    )
}

export default Library